<template>
  <div class="app">
    <image-box :src="require('../../assets/image/app/app_1.jpg')" :height="700"></image-box>
    <image-box :src="require('../../assets/image/app/app_2.jpg')" :height="625"></image-box>
    <image-box :src="require('../../assets/image/app/app_3.jpg')" :height="534"></image-box>
    <image-box :src="require('../../assets/image/app/app_4.jpg')" :height="643">
      <a class="app-4-button" href="https://v.qq.com/x/page/k35183dybvd.html" target="_blank"></a>
    </image-box>
    <image-box :src="require('../../assets/image/app/app_5.jpg')" :height="239"></image-box>
    <image-box :src="require('../../assets/image/app/app_6.jpg')" :height="606">
      <a class="app-5-button" href="https://v.qq.com/x/page/w3518lsozrd.html" target="_blank"></a>
    </image-box>
    <image-box :src="require('../../assets/image/app/app_7.jpg')" :height="627"></image-box>
    <image-box :src="require('../../assets/image/app/app_8.jpg')" :height="1025">
      <a class="app-7-button-1" href="https://mp.weixin.qq.com/s?__biz=MzAwODI5NjIxNg==&mid=2655579284&idx=1&sn=cd2f67bd49dfb50b23727dd9bd3eba8f&chksm=80cd4ee3b7bac7f5520edb4ab56e9ab0445673648559b3da5097543b74958deb383fe10faaef&token=1358357079&lang=zh_CN#rd" target="_blank"></a>
      <a class="app-7-button-2" href="https://mp.weixin.qq.com/s?__biz=MzAwODI5NjIxNg==&mid=2655581627&idx=2&sn=926444ccdfc5b4b7f73e3cee8c6f6509&chksm=80cd57ccb7badedaf4d204822200842eb4146762ae1aff4f80174c14aa299aab2d1a7d0087de&mpshare=1&scene=1&srcid=0820fTlyb5MGmMuqxmeFuVrx&sharer_sharetime=1566300265048&sharer_shareid=4dcbb941b59e952ed2eef955e564aeff&key=4e3623f5f138116da29a4e96b45a9e39d702dc8fe7c08b6c4cb58edc0a74958eb0424b6eb056f1e1548b0459828530bcf97f32eaadcc3526dd5e597f2f59fe178f651d20294baca3b3f20f2ac2ff5358&ascene=1&uin=OTE1NzEyMTU%3D&devicetype=Windows+10&version=62060833&lang=zh_CN&pass_ticket=kq6EvHQdtqSusU2C%2F5MAH27JZ83iVpJldJrv06oE6Ko%3D" target="_blank"></a>
      <a class="app-7-button-3" href="https://mp.weixin.qq.com/s?__biz=MzAwODI5NjIxNg==&mid=2655581466&idx=1&sn=ba26fd73718bde25d5bcafec1334960c&chksm=80cd576db7bade7b9eb386702c441407cbf6b6aa4faf1296cf98f3967d0ad66957063a90e019&mpshare=1&scene=1&srcid=0820ure5nrnQrPFGrntRUzEm&sharer_sharetime=1566300279459&sharer_shareid=4dcbb941b59e952ed2eef955e564aeff&key=6049ea3782f5de54f4a07b9f2dee5f69f6d6d6e6a0f18414d80e9a041369a3020cae5dfd69ad413a09f3d48f74213c3853c30ebc71e1555c7da06d27e7e74fe0a6d471f5ec408bf32b7b11c0d3bf1202&ascene=1&uin=OTE1NzEyMTU%3D&devicetype=Windows+10&version=62060833&lang=zh_CN&pass_ticket=kq6EvHQdtqSusU2C%2F5MAH27JZ83iVpJldJrv06oE6Ko%3D" target="_blank"></a>
      <a class="app-7-button-4" href="https://mp.weixin.qq.com/s/GbaoVK0Dxt3udce9YXRN4g" target="_blank"></a>
      <a class="app-7-button-5" href="https://mp.weixin.qq.com/s/0CWdwkiYJwHtxKsE_wTs2w" target="_blank"></a>
      <a class="app-7-button-6" href="https://mp.weixin.qq.com/s/vgkMXOxbICuIlx7sGV2RzA" target="_blank"></a>
    </image-box>
    <image-box :src="require('../../assets/image/app/app_9.jpg')" :height="817"></image-box>
  </div>
</template>

<script>
  import ImageBox from "../../components/ImageBox/ImageBox";
  export default {
    name: "about",
    components: {
      "image-box":ImageBox
    },
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "app_pc";
  }

  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "app_mobile";
  }
</style>
